<template>
    <div id="landing-container" :class="getMenuType">
        
            <router-view />
            <!-- ======= Footer ======= -->
            <footer id="footer">

                <div class="footer-top">
                    <div class="container">
                        <div class="row">

                            <div class="col-lg-4 col-md-6 footer-contact" data-aos="fade-up">
                                <h3><img src="/static/img/sole_tracking.png" alt="" class="img-fluid"></h3>
                                <p>
                                    <a href="https://api.whatsapp.com/send?phone=5213325507867"><strong>Whatsapp:</strong> +521 33 2550 7867<br></a>
                                    <strong>Correo:</strong> ventas@soletracking.com
                                </p>
                            </div>

                            <div class="col-lg-4 col-md-6 footer-links" data-aos="fade-up" data-aos-delay="100">
                                <h4>Enlaces útiles</h4>
                                <ul>
                                    <li><i class="bx bx-chevron-right"></i> <a href="#">Inicio</a></li>
                                    <li><i class="bx bx-chevron-right"></i> <a href="#">Términos y condiciones</a></li>
                                    <li><i class="bx bx-chevron-right"></i> <a href="/privacy">Políticas de privacidad</a></li>
                                </ul>
                            </div>

                            <div class="col-lg-4 col-md-6 footer-links" data-aos="fade-up" data-aos-delay="300">
                                <h4>Nuestras redes sociales</h4>
                                <p>Cras fermentum odio eu feugiat lide par naso tierra videa magna derita valies</p>
                                <div class="social-links mt-3">
                                    <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
                                    <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
                                    <a href="#" class="youtube"><i class="bx bxl-youtube"></i></a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="container py-4">
                    <div class="copyright">
                        &copy; Copyright <strong><span>SoLe</span></strong>. Todos los derechos reservados
                    </div>
                    <div class="credits">
                    </div>
                </div>
            </footer><!-- End Footer -->
    </div>
</template>

<script>
import Sidebar from '../../containers/Sidebar'
import TopNav from '../../containers/TopNav'
import Footer from '../../containers/Footer'
import {
    mapGetters
} from 'vuex'

export default {
    components: {
        'top-nav': TopNav,
        'sidebar': Sidebar,
        'footer-component': Footer
    },
    data() {
        return {
            show: false,
            user: {}
        }
    },
    computed: {
        ...mapGetters(['getMenuType'])
    },
    
}
</script>


<style scoped type="text/css" src="../../assets/css/style.css"></style>
